import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        redirect: "/home",
        component: HomeView,
        children: [
            {
                path: "/home",
                name: "home",
                component: () => import(/* webpackChunkName: "whois" */ "../views/home.vue"),
            },
            {
                path: "/whois",
                name: "whois",
                meta: {
                    title: "域名whois查询",
                },
                component: () => import(/* webpackChunkName: "whois" */ "../views/whois.vue"),
            },
            {
                path: "/photoInfo",
                name: "photoInfo",
                meta: {
                    title: "照片信息查询,exif",
                },
                component: () => import(/* webpackChunkName: "whois" */ "../views/photoInfo.vue"),
            },
            {
                path: "/fileMd5",
                name: "fileMd5",
                meta: {
                    title: "文件MD5转换",
                },
                component: () => import(/* webpackChunkName: "whois" */ "../views/fileMd5.vue"),
            },
            {
                path: "/totp",
                name: "totp",
                meta: {
                    title: "在线totp",
                },
                component: () => import(/* webpackChunkName: "whois" */ "../views/totp.vue"),
            },
            {
                path: "/crt",
                name: "crt",
                meta: {
                    title: "SSL查询",
                },
                component: () => import(/* webpackChunkName: "whois" */ "../views/crt.vue"),
            },
            {
                path: "/myip",
                name: "myip",
                meta: {
                    title: "我的IP",
                },
                component: () => import(/* webpackChunkName: "whois" */ "../views/myIp.vue"),
            },
            {
                path: "/speedtest",
                name: "speedtest",
                meta: {
                    title: "测试下载速度",
                },
                component: () => import(/* webpackChunkName: "whois" */ "../views/speedtest.vue"),
            },
            {
                path: "/shortUrl",
                name: "shortUrl",
                meta: {
                    login: true,
                    title: "短链",
                },
                component: () => import(/* webpackChunkName: "whois" */ "../views/shortUrl.vue"),
            },
        ],
    },
    {
        path: "/about",
        name: "about",
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login.vue"),
    },
    {
        path: "/linuxdo",
        name: "linuxdo",
        component: () => import(/* webpackChunkName: "login" */ "../views/login.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let pagetitle = to.meta.title || '';
    if (pagetitle) {
        pagetitle = pagetitle + " - ";
    }
    document.title = pagetitle + "V7.AL";
    if (to.meta.login) {
        let token = localStorage.getItem("v7token");
        if (!token) {
            next("/login");
        } else {
            next();
        }
    }
    next();
});

export default router;
